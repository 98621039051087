export enum RouteName {
  COMPENDIUM = "compendium",
  FACILITY_DETAILS = "facility_details",
  FIND_RESERVATION = "find_reservation",
  MY_RESERVATION = "my_reservation",
  HOTEL_HOME = "hotel_home",
  HOTEL_ROOT = "hotel_root",
  PROPERTY_INFO = "property_info",
  RESTAURANT_DETAILS = "restaurant_details",
  ROOM_UPGRADE_DETAILS = "room_upgrade_details",
  EARLY_CHECK_IN_DETAILS = "early_check_in_details",
  LATE_CHECK_OUT_DETAILS = "late_check_out_details",
  ADDON_DETAILS = "addon_details",
  COMPENDIUM_QR_POSTER = "compendium_qr_poster",
}
